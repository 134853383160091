import Vue from "vue";
import VueRouter from "vue-router";

import JwtService from "@/common/jwt.service";
import ApiService from "@/common/api.service";
import User from "@/apis/user.js";
import store from "@/store";
import roleMixin from "@/mixins/role.js";
import { MessageBox } from "element-ui";
import i18n from "@/i18n";
import Theme from "@/common/theme";
import baseDomainPath from "@/common/baseDomainPath";
import { replace } from "lodash";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/password/forgot",
    name: "ForgetPassword",
    component: () => import("@/views/ForgetPassword.vue")
  },
  {
    path: "/password/set",
    name: "SetPassword",
    component: () => import("@/views/SetPassword.vue")
  },
  {
    path: "/password/reset",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Login.vue")
  },
  // {
  //   path: "/user/:id",
  //   name: "ProfileForAdmin",
  //   component: () => import("@/views/user/ProfileForAdminEditUser.vue"),
  //   props: true,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: "/plans",
    name: "Plans",
    component: () => import("@/views/plans/Index.vue")
  },
  {
    path: "/others",
    name: "classes",
    component: () => import("@/views/header/Classes.vue")
  },
  {
    path: "/FAQ",
    name: "Instructors",
    component: () => import("@/views/header/Instructors.vue")
  },
  // ===== SAT 系统 ======
  {
    path: "/practices",
    name: "SATList",
    component: () => import("@/views/satList/Practices.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/",
    name: "SATPractices",
    component: () => import("@/views/satList/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/courses",
    name: "SATClasses",
    component: () => import("@/views/AllClasses/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () => import("@/views/profile/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/classes",
    name: "MyClasses",
    component: () => import("@/views/myClasses/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/classes-i-created",
    name: "TeacherClasses",
    component: () => import("@/views/myClasses/Teacher.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/performance",
    name: "SATPerformance",
    component: () => import("@/views/performance/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/Pro",
    name: "SATPro",
    component: () => import("@/views/Pro.vue")
  },

  {
    path: "/flashCards",
    name: "FlashCards",
    component: () => import("@/views/flashCards/Index.vue")
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/test/:practiceType/:id",
    name: "SATTest",
    component: () => import("@/views/satTest/Index.vue"),
    meta: {
      requiresAuth: true,
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/test/:practiceType/:id/start",
    name: "SATTestLockPage",
    component: () => import("@/views/satTest/LockPage.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/class/exam/:classExamId",
    name: "SATTestClassPage",
    component: () => import("@/views/satTest/ClassPage.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/test/m/:practiceType/:id",
    name: "SATMobileTest",
    component: () => import("@/views/satTest/mobile/Index.vue"),
    meta: {
      requiresAuth: true,
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/viewTest",
    name: "ViewSATTest",
    component: () => import("@/views/viewSatTest/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/testresults",
    name: "SATResults",
    component: () => import("@/views/satTestResultsPage/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/result/:id",
    name: "SATResult",
    component: () => import("@/views/satUserTestResult/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/explanation/question/:questionId",
    name: "QuestionExplanation",
    component: () => import("@/views/satTestQuestionExplanation/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/class/explanation/question/:questionId",
    name: "ClassQuestionExplanation",
    component: () => import("@/views/classQuestionExplanation/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/explanation/m/question/:questionId",
    name: "QuestionExplanationM",
    component: () => import("@/views/satTestQuestionExplanation/Index_M.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/fullPractice",
    name: "SatComposePractice",
    component: () => import("@/views/satComposePractice/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/fullPractice/detail/all",
    name: "SatComposePracticeDetailPDF",
    component: () => import("@/views/satComposePractice/all.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/fullPractice/detail/browse",
    name: "SatComposePracticeDetailAll",
    component: () => import("@/views/satComposePractice/Browse.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: "/practice/detail/all",
  //   name: "SatPracticeDetailAll",
  //   component: () => import("@/views/satPractice/all.vue")
  // },
  {
    path: "/adaptive/detail/all",
    name: "SatAdaptiveDetailAll",
    component: () => import("@/views/satAdaptive/all.vue")
  },
  // {
  //   path: "/practice",
  //   name: "SatPractice",
  //   component: () => import("@/views/satPractice/Index.vue"),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: "/courseDetail/:id",
    name: "StudentCourseDetail",
    component: () => import("@/views/courses/StudentDetail.vue"),
    meta: {
      requiresAuth: true,
      showFooter: false
    }
  },
  {
    path: "/teacher/courseDetail/:id",
    name: "TeacherCourseDetail",
    component: () => import("@/views/courses/StudentDetail.vue"),
    meta: {
      requiresAuth: true,
      showFooter: false
    }
  },
  {
    path: "/video-classes",
    name: "VideoClasses",
    component: () => import("@/views/videoClasses/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/video-classes/detail",
    name: "VideoClassesDetail",
    component: () => import("@/views/videoClasses/Detail.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("@/views/company/Index.vue"),
    redirect: "/company/courses",
    meta: {
      requiresAuth: true,
      showFooter: false,
      role: "company"
    },
    children: [
      {
        path: "user/:id",
        name: "CompanyAdminViewUser",
        component: () => import("@/views/profile/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "courseDetail/:id",
        name: "CompanyCourseDetail",
        component: () => import("@/views/courses/StudentDetail.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "allQuestions",
        name: "CompanyAllQuestions",
        component: () => import("@/views/allQuestions/View.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "practices",
        name: "CompanySatManagement",
        component: () => import("@/views/management/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "teachers",
        name: "CompanyTeachers",
        component: () => import("@/views/company/Teachers.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "users",
        name: "CompanyUsers",
        component: () => import("@/views/company/Users.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "info",
        name: "CompanyInfo",
        component: () => import("@/views/company/Info.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "info",
        name: "CompanyDrill",
        component: () => import("@/views/company/Info.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "courses",
        name: "CompanySatCourses",
        component: () => import("@/views/courses/List.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "course/add",
        name: "CompanySatAddCourse",
        component: () => import("@/views/courses/Add.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "course/edit/:id",
        name: "CompanyEditCourse",
        component: () => import("@/views/courses/Add.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      },
      {
        path: "results",
        name: "CompanyViewAllResults",
        component: () => import("@/views/satTestResultsPage/Admin.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "company"
        }
      }
    ]
  },
  {
    path: "/teacher",
    name: "Teacher",
    component: () => import("@/views/teacher/Index.vue"),
    redirect: "/teacher/courses",
    meta: {
      requiresAuth: true,
      showFooter: false,
      role: "teacher"
    },
    children: [
      {
        path: "users",
        name: "TeacherUsers",
        component: () => import("@/views/AdminUsers/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "practice/addTest",
        name: "TeacherSatAddPractice",
        component: () => import("@/views/satAddTest/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "addQuestion",
        name: "TeacherSatAddQuestion",
        component: () => import("@/views/satAddQuestion/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "editQuestion",
        name: "TeacherSatEditQuestion",
        component: () => import("@/views/satAddQuestion/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "duplicateQuestion",
        name: "TeacherSatDuplicateQuestion",
        component: () => import("@/views/satAddQuestion/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "allQuestions",
        name: "TeacherAllQuestions",
        component: () => import("@/views/allQuestions/View.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "results",
        name: "TeacherViewAllResults",
        component: () => import("@/views/satTestResultsPage/Admin.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "practice/addTest",
        name: "TeacherSatAddPractice",
        component: () => import("@/views/satAddTest/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "practice/detail",
        name: "TeacherSatPracticeDetail",
        component: () => import("@/views/satPractice/edit.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "fullPractice/detail",
        name: "TeacherSatComposePracticeDetail",
        component: () => import("@/views/satComposePractice/edit.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "practices",
        name: "TeacherSatManagement",
        component: () => import("@/views/management/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "course/add",
        name: "TeacherSatAddCourse",
        component: () => import("@/views/courses/Add.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "user/:id",
        name: "TeacherViewUser",
        component: () => import("@/views/profile/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "course/edit/:id",
        name: "TeacherEditCourse",
        component: () => import("@/views/courses/Add.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      // {
      //   path: "course/:id",
      //   name: "TeacherCourseDetailBackend",
      //   component: () => import("@/views/courses/StudentDetail.vue"),
      //   meta: {
      //     showFooter: false
      //   }
      // },
      {
        path: "courses",
        name: "TeacherSatCourses",
        component: () => import("@/views/courses/List.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "analysis/:id",
        name: "TeacherAnalysis",
        component: () => import("@/views/courses/TestAnalysis.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "analysis/:id/detail",
        name: "TeacherAdaptiveAnalysisDetail",
        component: () => import("@/views/courses/AdaptiveAnalysisDetail.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "analysis/adaptive/:id",
        name: "TeacherAdaptiveAnalysis",
        component: () => import("@/views/courses/AdaptiveAnalysis.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      },
      {
        path: "user/:id",
        name: "TeacherViewUser",
        component: () => import("@/views/profile/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "teacher"
        }
      }
    ]
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      requiresAuth: true,
      showFooter: false,
      role: "admin"
    },
    component: () => import("@/views/admin/Index.vue"),
    redirect: "/admin/practices",
    children: [
      {
        path: "courses",
        name: "AdminSatCourses",
        component: () => import("@/views/courses/List.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "user/:id",
        name: "AdminViewUser",
        component: () => import("@/views/profile/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "results",
        name: "ViewAllResults",
        component: () => import("@/views/satTestResultsPage/Admin.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "analysis/:id",
        name: "AdminAnalysis",
        component: () => import("@/views/courses/TestAnalysis.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "analysis/:id/detail",
        name: "AdminAdaptiveAnalysisDetail",
        component: () => import("@/views/courses/AdaptiveAnalysisDetail.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "analysis/adaptive/:id",
        name: "AdminAdaptiveAnalysis",
        component: () => import("@/views/courses/AdaptiveAnalysis.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "userResults/:type/:examId",
        name: "ResultsByExams",
        component: () =>
          import("@/views/satTestResultsPage/ResultsByExams.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "results/combine",
        name: "ResultsCombine",
        component: () => import("@/views/satTestResultsPage/CombineTests.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "practices",
        name: "SatManagement",
        component: () => import("@/views/management/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      // {
      //   path: "/admin/adaptive",
      //   name: "SatManagementAdaptive",
      //   component: () => import("@/views/management/Index.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     showFooter: false
      //   }
      // },
      //题库
      {
        path: "allQuestions",
        name: "AllQuestions",
        component: () => import("@/views/allQuestions/View.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "questionTags",
        name: "QuestionTags",
        component: () => import("@/views/questionTags/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "practice/addTest",
        name: "SatAddPractice",
        component: () => import("@/views/satAddTest/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },

      {
        path: "composePractice/addTest",
        name: "SatAddComposePractice",
        component: () => import("@/views/satAddTest/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "/adaptive/addTest",
        name: "SatAddAdaptive",
        component: () => import("@/views/satAddTest/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "addQuestion",
        name: "SatAddQuestion",
        component: () => import("@/views/satAddQuestion/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "editQuestion",
        name: "SatEditQuestion",
        component: () => import("@/views/satAddQuestion/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "duplicateQuestion",
        name: "SatDuplicateQuestion",
        component: () => import("@/views/satAddQuestion/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "fullPractice/detail",
        name: "SatComposePracticeDetail",
        component: () => import("@/views/satComposePractice/edit.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "/adaptive/detail",
        name: "SatAdaptiveDetail",
        component: () => import("@/views/satAdaptive/edit.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "/fullPractice/practice/detail/:composePracticeId",
        name: "SatPracticeFromFullDetail",
        component: () => import("@/views/satPractice/edit.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "practice/detail",
        name: "SatPracticeDetail",
        component: () => import("@/views/satPractice/edit.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "score-table/:type/:practiceId/:id",
        name: "ScoreTable",
        component: () => import("@/views/scoreTable/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "score-table/:type/:practiceId/create/new",
        name: "CreateScoreTable",
        component: () => import("@/views/scoreTable/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "users",
        name: "AdminUsers",
        component: () => import("@/views/AdminUsers/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "company/",
        name: "AdminCompany",
        component: () => import("@/views/AdminCompany/Index.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "company/users/:id",
        name: "AdminCompanyUsers",
        component: () => import("@/views/AdminCompany/Users.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "company/info/:id",
        name: "AdminCompanyInfo",
        component: () => import("@/views/AdminCompany/Info.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "users/logs",
        name: "AdminUsersLogs",
        component: () => import("@/views/AdminUsers/Logs.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },

      {
        path: "course/add",
        name: "AdminSatAddCourse",
        component: () => import("@/views/courses/Add.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "course/edit/:id",
        name: "AdminEditCourse",
        component: () => import("@/views/courses/Add.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      },
      {
        path: "courseDetail/:id",
        name: "AdminCourseDetail",
        component: () => import("@/views/courses/StudentDetail.vue"),
        meta: {
          requiresAuth: true,
          showFooter: false,
          role: "admin"
        }
      }
    ]
  },
  {
    path: "/preview/question/:questionId",
    name: "QuestionPreview",
    component: () => import("@/views/satTestQuestionExplanation/Admin.vue"),
    meta: {
      requiresAuth: true,
      showFooter: false
    }
  },
  {
    path: "/join",
    name: "JoinCourse",
    component: () => import("@/views/myClasses/Join.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/company/join",
    name: "JoinCompany",
    component: () => import("@/views/AdminCompany/Join.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/practice/detail/all",
    name: "SatPracticeDetailAllPDF",
    component: () => import("@/views/satPractice/all.vue")
  },
  {
    path: "/practice/detail/browse",
    name: "SatPracticeDetailAll",
    component: () => import("@/views/satPractice/Browse.vue")
  },
  {
    path: "/browse",
    name: "Browse",
    component: () => import("@/views/satList/Browse.vue")
  },
  {
    path: "/browse/examination",
    name: "Examination",
    component: () => import("@/views/satList/Examination.vue")
  },
  {
    path: "/browse/passages",
    name: "Passages",
    component: () => import("@/views/passages/Index.vue")
  },
  {
    path: "/browse/passage",
    name: "Passage",
    component: () => import("@/views/passages/Passage.vue")
  },
  {
    path: "/passage/edit",
    name: "EditPassage",
    component: () => import("@/views/passages/Edit.vue")
  },
  {
    path: "/passage/create",
    name: "CreatePassage",
    component: () => import("@/views/passages/Create.vue")
  },
  {
    path: "/browse/questions",
    name: "Questions",
    component: () => import("@/views/questions/Index.vue")
  },
  {
    path: "/browse/question",
    name: "Question",
    component: () => import("@/views/questions/Question.vue")
  },
  {
    path: "/question/edit",
    name: "EditQuestion",
    component: () => import("@/views/questions/Edit.vue")
  },
  {
    path: "/systemcheck",
    name: "SystemCheck",
    component: () => import("@/views/SystemCheck.vue")
  },
  // {
  //   path: "/scorereport",
  //   name: "ToeflScorereport",
  //   component: () => import("@/views/transcript/Scorereport.vue")
  // },
  // {
  //   path: "/setTest",
  //   name: "SetTest",
  //   component: () => import("@/views/satTest/Index.vue")
  // },
  // {
  //   path: "/m/setTest",
  //   name: "MSetTest",
  //   component: () => import("@/views/satTest/mobile/Index.vue")
  // },
  // {
  //   path: "/testresults",
  //   name: "SatTestResults",
  //   component: () => import("@/views/satTestResults/Index.vue")
  // },
  // {
  //   path: "/transcript",
  //   name: "Transcript",
  //   component: () => import("@/views/transcript/Index.vue")
  // },
  // {
  //   path: "/result",
  //   name: "result",
  //   component: () => import("@/views/transcript/Index.vue")
  // },
  // {
  //   path: "/result/pdf",
  //   name: "ResultPDF",
  //   component: () => import("@/views/transcript/PDF.vue")
  // },
  // {
  //   path: "/gradingsamples",
  //   name: "Gradingsamples",
  //   component: () => import("@/views/transcript/Example.vue")
  // },
  // {
  //   path: "/explanation",
  //   name: "Resolve",
  //   component: () => import("@/views/resolve/Index.vue"),
  //   meta: {
  //     showFooter: false
  //   }
  // },
  // {
  //   path: "/browse/detail",
  //   name: "ToeflResolveBrowse",
  //   component: () => import("@/views/resolve/Browse.vue"),
  //   meta: {
  //     showFooter: false
  //   }
  // },
  // {
  //   path: "/test",
  //   name: "SatTest",
  //   component: () => import("@/views/satTest/Test.vue"),
  //   meta: {
  //     showHeader: false,
  //     showFooter: false
  //   }
  // },
  // {
  //   path: "/m/test",
  //   name: "MSatTest",
  //   component: () => import("@/views/satTest/mobile/Test.vue"),
  //   meta: {
  //     showHeader: false,
  //     showFooter: false
  //   }
  // },
  //Grading
  // {
  //   path: "/report",
  //   name: "ToeflGrading",
  //   component: () => import("@/views/grading/Index.vue")
  // },
  //GradingList
  // {
  //   path: "/grading",
  //   name: "ToeflTestGrading",
  //   component: () => import("@/views/gradingList/Index.vue")
  // },
  // Balance
  {
    path: "/balance",
    name: "Balance",
    component: () => import("@/views/balance/Index.vue")
  },
  //Balance
  // {
  //   path: "/profile/balance",
  //   name: "ToeflProfileBalance",
  //   component: () => import("@/views/balance/Index.vue")
  // },
  // Email Verify
  {
    path: "/email/verify/:id/:hash",
    name: "Verify",
    component: () => import("@/views/Verify.vue"),
    meta: {
      requiresAuth: true,
      skipCheckBind: true
    }
  },
  // Email Verify
  // {
  //   path: "/writingContent",
  //   name: "WritingContent",
  //   component: () => import("@/views/satTest/writing/ViewAll.vue"),
  //   meta: {
  //     skipCheckBind: true
  //   }
  // },
  //Analytics
  {
    path: "/analytics",
    name: "ToeflAnalytics",
    component: () => import("@/views/analytics/Index.vue"),
    meta: {
      isAdminPage: true
    }
  },
  // {
  //   path: "/payments",
  //   name: "ToeflPayments",
  //   component: () => import("@/views/payments/Index.vue"),
  //   meta: {
  //     isAdminPage: true
  //   }
  // },
  {
    path: "/students",
    name: "ToeflStudents",
    component: () => import("@/views/students/Index.vue"),
    meta: {
      isAdminPage: true
    }
  },
  // TODO: 404
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue")
  },
  {
    path: "/403",
    name: "PageRole",
    component: () => import("@/views/PageRole.vue")
  },
  {
    path: "*",
    name: "RedirectToNotFound",
    component: () => import("@/views/RedirectToNotFound.vue")
  }
];

const theme = Theme.name;
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 導向新的 router 會到新頁面的 top
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const ignoreRemeberPage = [
    "Login",
    "PageNotFound",
    "ForgetPassword",
    "SetPassword",
    "ResetPassword",
    "Verify"
  ];
  updateFavicon("name", Theme.icon, Theme.image);

  if (!ignoreRemeberPage.includes(to.name)) {
    localStorage.setItem("lastAccessRoutePath", to.fullPath);
  }

  let isAdmin = false;
  let isCompanyAdmin = false;
  let isTeacher = false;
  let user = null;
  console.log(JwtService.hasToken());
  if (to.meta.requiresAuth && !JwtService.hasToken()) {
    if (theme !== "Ivy-Way") {
      next({ name: "Login" });
    }
  } else {
    if (JwtService.hasToken()){
      if (!user) {
        const getProfile = function() {
          return ApiService.query("auth/me");
        };
        await getProfile()
          .then(profile => {
            user = profile.user;
            if (user.all_roles.includes("super-admin")) {
              isAdmin = true;
            }
            if (user.all_roles.includes("teacher")) {
              isTeacher = true;
            }
            if (user.all_roles.includes("company-admin")) {
              isCompanyAdmin = true;
            }
          })
          .catch(res => {
            if (theme !== "Ivy-Way") {
              next({ name: "Login" });
            }
          });
      }
      if (!isAdmin && to.meta.role === "admin") {
        next({ name: "PageRole", replace: true });
      }
      if (!isCompanyAdmin && to.meta.role === "company") {
        next({ name: "PageRole", replace: true });
      }
      if (!isTeacher && to.meta.role === "teacher") {
        next({ name: "PageRole", replace: true });
      }
    }
  }
  next();
});
const updateFavicon = (title, imgPath, image) => {
  const link = document.querySelector("link[rel*='icon']");
  link.rel = "icon";
  link.href = baseDomainPath + imgPath + "?" + new Date().getTime();

  const meta = document.querySelector("meta[property*='image']");
  meta.property = "og:image";
  meta.content = image;
};
router.onError(error => {
  const pattern = "Loading chunk";
  const isChunkLoadingFailed = error.message.includes(pattern);
  const cssPattern = "Loading CSS chunk";
  const isCSSChunkLoadingFailed = error.message.includes(cssPattern);
  if (isChunkLoadingFailed || isCSSChunkLoadingFailed) {
    MessageBox({
      title: i18n.t("message.notice"),
      message: `
        ${i18n.t(
    "message.chunkLoadingError1"
  )}<a target="_blank" href="https://www.messenger.com/t/333718851200334/?messaging_source=source%3Apages%3Amessage_shortlink">
        ${i18n.t("message.chunkLoadingError2")}</a>${i18n.t(
  "message.chunkLoadingError3"
)}
      `,
      confirmButtonText: i18n.t("alert.refresh"),
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      dangerouslyUseHTMLString: true,
      callback: () => window.location.reload()
    });
  }
});

export default router;
